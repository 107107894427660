import { editorLocalization } from "../editorLocalization";

var spanishTranslation = {
  survey: {
    edit: "Editar",
    externalHelpLink: "Mira y aprende a crear encuestas",
    externalHelpLinkUrl:
      "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion:
      "¡Arrastra un tipo de pregunta para empezar a diseñar tu encuesta!",
    addLogicItem: "Cree una regla para personalizar el flujo de la encuesta",
    copy: "Copiar",
    duplicate: "Duplicar",
    addToToolbox: "Añadir a la caja de herramientas",
    deletePanel: "Eliminar panel",
    deleteQuestion: "Eliminar pregunta",
    convertTo: "Convertir a",
    drag: "Elemento de arrastre",
  },
  qt: {
    default: "Defecto",
    checkbox: "Caja",
    comment: "Comentario",
    imagepicker: "Selector de imágenes",
    ranking: "Ranking",
    image: "Imagen",
    dropdown: "Desplegable",
    file: "Archivo",
    html: "Html",
    matrix: "Matriz (selección única)",
    matrixdropdown: "Matriz (opción múltiple)",
    matrixdynamic: "Matriz (filas dinámicas)",
    multipletext: "Múltiples textos",
    panel: "Panel",
    paneldynamic: "Panel (paneles dinámicos)",
    radiogroup: "Grupo de radio",
    rating: "Clasificación",
    text: "Entrada única",
    boolean: "Booleano",
    expression: "Expresión (solo lectura)",
    signaturepad: "Almohadilla de firma",
    flowpanel: "Panel de flujo",
  },
  ed: {
    defaultLocale: "Predeterminado ({0})",
    survey: "Encuesta",
    settings: "Configuración de la encuesta",
    settingsTooltip: "Configuración de encuesta abierta",
    opjectPropertiesHeader: "Avanzada",
    showObjectProperties: "Mostrar propiedades del objeto",
    hideObjectProperties: "Ocultar propiedades de objetos",
    showPanel: "Mostrar panel",
    hidePanel: "Ocultar panel",
    editSurvey: "Editar encuesta",
    addNewPage: "Añadir nueva página",
    moveRight: "Desplácese a la derecha",
    moveLeft: "Desplácese a la izquierda",
    deletePage: "Eliminar página",
    editPage: "Editar página",
    edit: "Editar",
    newPageName: "Página",
    newQuestionName: "Pregunta",
    newPanelName: "Panel",
    newTextItemName: "Texto",
    testSurvey: "Encuesta de prueba",
    testSurveyAgain: "Encuesta de prueba de nuevo",
    testSurveyWidth: "Ancho de la encuesta:",
    navigateToMsg: "Tuviste que navegar a:",
    logic: "Lógica de la encuesta",
    embedSurvey: "Incrustar la encuesta",
    translation: "Traducción",
    saveSurvey: "Guardar la encuesta",
    saveSurveyTooltip: "Guardar la encuesta",
    designer: "Diseñador de la encuesta",
    jsonEditor: "Editor de JSON",
    undo: "Deshacer",
    redo: "Rehacer",
    undoTooltip: "Deshacer el último cambio",
    redoTooltip: "Rehacer el cambio",
    copy: "Copiar",
    cut: "Cortar",
    paste: "Pegar",
    copyTooltip: "Copia selección al portapapeles",
    cutTooltip: "Corta la selección al portapapeles",
    pasteTooltip: "Pega de portapapeles",
    options: "Opciones",
    generateValidJSON: "Generar un JSON válido",
    generateReadableJSON: "Generar JSON legible",
    toolbox: "Caja de herramientas",
    "property-grid": "Propiedades",
    toolboxGeneralCategory: "general",
    delSelObject: "Eliminar objeto seleccionado",
    editSelObject: "Editar objeto seleccionado",
    correctJSON: "Por favor, corrija JSON",
    surveyResults: "Resultado de la encuesta:",
    surveyResultsTable: "Como Tabla",
    surveyResultsJson: "Como JSON",
    resultsTitle: "Título de la pregunta",
    resultsName: "Nombre de la pregunta",
    resultsValue: "Valor de respuesta",
    resultsDisplayValue: "Valor de visualización",
    modified: "Modificada",
    saving: "Guardando",
    saved: "Guardado",
    propertyEditorError: "error:",
    saveError: "¡Error!El contenido del editor no se ha guardado",
    translationAddLanguage: "Seleccione el idioma para traducir",
    translationShowAllStrings: "Mostrar todos los textos",
    translationShowAllPages: "Mostrar todas las páginas",
    translationNoStrings:
      "Sin cuerdas para traducir. Por favor, cambie el filtro",
    translationExportToSCVButton: "Exportar a CSV",
    translationImportFromSCVButton: "Importación de CSV",
    translationMergeLocaleWithDefault: "Merge {0} con lugar predeterminado",
    bold: "Negrita",
    italic: "Itálica",
    underline: "Subrayar",
    fpAddQuestion: "Añadir pregunta ...",
    selectPage: "Seleccionar página ...",
    chooseElement: "Elige el elemento ...",
    completedHtmlOnConditionItemText: "Mostrar si:",
    lg: {
      addNewItem: "",
      page_visibilityName: "Visibilidad de la pagina",
      panel_visibilityName: "Visibilidad del panel",
      panel_enableName: "Panel habilitar / deshabilitar",
      question_visibilityName: "Pregunta visibilidad",
      question_enableName: "Pregunta habilitar / deshabilitar",
      question_requireName: "Pregunta opcional requerida",
      trigger_completeName: "Encuesta completa",
      trigger_setvalueName: "Establecer valor de la pregunta",
      trigger_copyvalueName: "Copiar valor de la pregunta",
      trigger_skipName: "Saltar a la pregunta",
      trigger_runExpressionName: "Ejecutar la expresión personalizada",
      completedHtmlOnConditionName: "Texto personalizado 'Página de agradecimiento'",
      page_visibilityDescription:
        "Haga que la página sea visible cuando la expresión lógica vuelva verdadera. De lo contrario, manténgalo invisible",
      panel_visibilityDescription:
        "Haga que el panel sea visible cuando la expresión lógica vuelva verdadera. De lo contrario, manténgalo invisible",
      panel_enableDescription:
        "Haga el panel, y todos los elementos dentro de él, habiliten cuando la expresión lógica devuelve verdadero. De lo contrario, manténgalos deshabilitados",
      question_visibilityDescription:
        "Haz que la pregunta sea visible cuando la expresión lógica se devuelve 'verdadero'. De lo contrario, se mantiene invisible",
      question_enableDescription:
        "Haz que la pregunta se habilite cuando la expresión lógica devuelve 'verdadero'. De lo contrario, se mantiene deshabilitado",
      question_requireDescription:
        "La pregunta se requiere cuando la expresión lógica devuelve 'verdadero'",
      trigger_completeDescription:
        "Cuando la expresión lógica devuelve 'verdadero', la encuesta se completa y el usuario final ve la 'página de agradecimiento'",
      trigger_setvalueDescription:
        "Cuando se cambian los valores de las preguntas, que se usan en la expresión lógica, se cambian y la expresión lógica devuelve 'verdadero', entonces el valor se establece en la pregunta seleccionada",
      trigger_copyvalueDescription:
        "Cuando se cambian los valores de las preguntas, que se usan en la expresión lógica, se cambian y la expresión lógica devuelve 'verdadero', entonces el valor de una pregunta seleccionada se copia a otra pregunta seleccionada",
      trigger_skipDescription:
        "Cuando la expresión lógica devuelve 'verdadero', la encuesta salta a la pregunta seleccionada",
      trigger_runExpressionDescription:
        "Cuando la expresión lógica devuelve 'verdadero', entonces se realiza la expresión personalizada. Es posible que opcionalmente establezca este resultado de expresión en la pregunta seleccionada",
      completedHtmlOnConditionDescription:
        "Si la expresión lógica devuelve 'verdadero', entonces el texto predeterminado para la 'página de agradecimiento' se cambia por el texto dado",
      itemExpressionText: "Cuando la expresión: '{0}' devuelve VERDADERO:",
      page_visibilityText: "Hacer la página {0} Visible",
      panel_visibilityText: "Hacer panel {0} visible",
      panel_enableText: "Hacer panel {0} habilitar",
      question_visibilityText: "Hacer Pregunta {0} Visible",
      question_enableText: "Hacer Pregunta {0} Habilitar",
      question_requireText: "Hacer la pregunta {0} requerida",
      trigger_completeText: "La encuesta se completa",
      trigger_setvalueText: "En cuestión: {0} valor {1}",
      trigger_copyvalueText: "Copiar en cuestión: {0} valor de la pregunta {1}",
      trigger_skipText: "Encuesta salta a la pregunta {0}",
      trigger_runExpressionText1: "Expresión de ejecución: '{0}'",
      trigger_runExpressionText2: "y establecer su resultado en cuestión: {0}",
      completedHtmlOnConditionText:
        "Mostrar texto personalizado para la 'gracias página'",
      conditions: "Condición(es)",
      actions: "Comportamiento",
      expressionEditorTitle: "Definir la(s) condición(es)",
      actionsEditorTitle: "Definir la(s) acción(es)",
      deleteAction: "Eliminar acción",
      addNewAction: "Añadir nueva acción",
      selectedActionCaption: "Seleccione una acción para agregar ...",
      expressionInvalid:
        "La expresión lógica está vacía o inválida. Por favor corríjalo",
      noActionError: "Por favor, agregue al menos una acción",
      actionInvalid: "Por favor, solucione problemas en su(s) acción(es)",
      expressionSetup: "",
      actionsSetup: " ",
    },
  },
  pel: {
    isRequired: "¿Requerida?",
  },
  pe: {
    apply: "Solicitar",
    ok: "ok",
    save: "Guardar",
    saveTooltip: "Guardar",
    cancel: "Cancelar",
    reset: "Reiniciar",
    refresh: "Actualizar",
    close: "Cerrar",
    delete: "Borrar",
    add: "Agregar",
    addNew: "Añadir nuevo",
    addItem: "Haga clic para agregar un artículo ...",
    addOther: "Otra",
    addSelectAll: "Seleccionar todo",
    addNone: "Ninguna",
    removeAll: "Eliminar todo",
    edit: "Editar",
    back: "Volver sin guardar",
    backTooltip: "Volver sin guardar",
    saveAndBack: "Guardar y volver",
    saveAndBackTooltip: "Guardar y volver",
    itemValueEdit: "Visible si",
    editChoices: "Editar opciones",
    showChoices: "Mostrar opciones",
    move: "Moverse",
    empty: "<vaciar>",
    notEmpty: "<Editar valor>",
    fastEntry: "Entrada rápida",
    formEntry: "Entrada de forma",
    testService: "Probar el servicio",
    itemSelectorEmpty: "Por favor seleccione el elemento",
    conditionActionEmpty: "Por favor seleccione la acción",
    conditionSelectQuestion: "Seleccionar pregunta ...",
    conditionSelectPage: "Seleccionar página ...",
    conditionSelectPanel: "Seleccionar panel ...",
    conditionValueQuestionTitle: "Por favor ingrese / seleccione el valor",
    conditionHelp:
      "Por favor ingrese una expresión booleana.Debe devolverse verdadero para mantener visible la pregunta / página.Por ejemplo: {pregunta1} = 'value1' o ({pregunta2} * {pregunta4}> 20 y {pregunta3} <5)",
    expressionHelp:
      "Puede usar soportes rizados para obtener acceso a los valores de la pregunta: {pregunta1} + {pregunta2}, ({precio} * {cantidad}) * (100 - {descuento}).Puede usar funciones como: IIF (), hoy (), edad (), min (), max (), cuenta (), AVG () y otros",
    aceEditorHelp:
      "Presione CTRL + ESPACIO para obtener sugerencia de completar la expresión",
    aceEditorRowTitle: "Fila actual",
    aceEditorPanelTitle: "Panel actual",
    showMore: "Para más detalles, por favor revise la documentación",
    conditionShowMoreUrl:
      "https://surveyjsIo/documentation/libraryParametro?Id =PreguntaYParámetro =VisibleSi",
    assistantTitle: "Preguntas disponibles:",
    cellsEmptyRowsColumns: "Debe haber al menos una columna o fila",
    propertyIsEmpty: "Porfavor introduzca un valor",
    propertyIsNoUnique: "Por favor ingrese un valor único",
    propertyNameIsNotUnique: "Por favor ingrese un nombre único",
    listIsEmpty: "Añadir un nuevo artículo",
    expressionIsEmpty: "La expresión está vacía",
    value: "Valor",
    text: "Texto",
    rowid: "ID de fila",
    imageLink: "Enlace de imágen",
    columnEdit: "Editar columna: {0}",
    itemEdit: "Editar artículo: {0}",
    url: "URL",
    path: "Camino",
    valueName: "Nombre de valor",
    titleName: "Nombre del título",
    allowEmptyResponse: "Permitir respuestas vacías",
    titlePlaceholder: "Título de entrada aquí",
    surveyTitlePlaceholder: "Título de la encuesta de entrada aquí",
    pageTitlePlaceholder: "Título de la página de entrada aquí",
    descriptionPlaceholder: "Ingrese una descripción",
    surveyDescriptionPlaceholder: "Ingrese una descripción de la encuesta",
    pageDescriptionPlaceholder: "Ingrese una descripción de la página",
    hasOther: "Tiene opción 'Otro'",
    otherText: "Cambiar texto 'Otro'",
    hasNone: "Tiene opcion 'Ninguno'",
    noneText: "Cambiar texto 'Ninguno'",
    hasSelectAll: "Tiene opción 'Selecionar todo'",
    selectAllText: "Cambiar texto de 'Seleccionar todo'",
    choicesMin: "Valor mínimo para artículos generados automáticos",
    choicesMax: "Valor máximo para artículos generados automáticos",
    choicesStep: "La diferencia entre los artículos generados automáticos",
    name: "Nombre",
    title: "Título",
    cellType: "Tipo de célula",
    colCount: "Recuento de columnas",
    choicesOrder: "Seleccione el orden de las opciones",
    visible: "¿Es visible?",
    isRequired: "¿Se requiere?",
    isAllRowRequired: "Requerir respuesta para todas las filas",
    requiredErrorText: "Texto de error requerido",
    startWithNewLine: "¿Empieza con la nueva línea?",
    rows: "Número de filas",
    cols: "Recuento de columnas",
    placeholder: "Placeholder de entrada",
    showPreview: "Mostrar área de vista previa",
    storeDataAsText:
      "Tienda el contenido del archivo en el resultado de JSON como texto",
    maxSize: "Tamaño máximo de archivo en bytes",
    imageHeight: "Altura de imagen",
    imageWidth: "Ancho de la imagen",
    rowCount: "Número de filas",
    columnLayout: "Diseño de columnas",
    addRowLocation: "Añadir la ubicación del botón de la fila",
    addRowText: "Añadir texto de botón de fila",
    removeRowText: "Eliminar el texto del botón de fila",
    rateMin: "Valor mínimo",
    rateMax: "Valor máximo",
    rateStep: "Rango de los valores",
    minRateDescription: "Descripción del valor mínimo",
    maxRateDescription: "Descripción del valor máximo",
    inputType: "Tipo de entrada",
    optionsCaption: "Cambie texto de 'Seleccione'",
    showOptionsCaption: "Mostrar el título de las opciones",
    defaultValue: "Valor por defecto",
    cellsDefaultRow: "Textos de celdas predeterminados",
    surveyEditorTitle: "Editar configuración de encuesta",
    qEditorTitle: "Editar: {0}",
    maxLength: "Longitud máxima",
    buildExpression: "Construir",
    editExpression: "Editar",
    and: "Y",
    or: "O",
    remove: "Eliminar",
    addCondition: "Añadir condición",
    showTitle: "Mostrar / Ocultar Título",
    expandCollapseTitle: "Expandir / Contraer título",
    locale: "Idioma predeterminado",
    simulator: "Elige dispositivo",
    landscapeOrientation: "Orientación horizontal ",
    mode: "Modo (editar / leer solamente)",
    clearInvisibleValues: "Claros valores invisibles",
    cookieName:
      "Nombre de la cookie (para deshabilitar la encuesta de ejecución dos veces localmente)",
    sendResultOnPageNext:
      "Enviar resultados de encuestas en la página Siguiente",
    storeOthersAsComment: "Almacenar 'Otros' valor en campo separado",
    showPageTitles: "Mostrar descripción de la página",
    showPageNumbers: "Mostrar números de página",
    pagePrevText: "Página de texto del botón anterior",
    pageNextText: "Página Siguiente botón de texto",
    completeText: "Texto completo del botón",
    previewText: "Vista previa del botón del botón",
    editText: "Editar botón de texto",
    startSurveyText: "Texto de inicio de la encuesta",
    showNavigationButtons:
      "Mostrar botones de navegación (navegación predeterminada)",
    showPrevButton:
      "Mostrar botón anterior (el usuario puede volver a la página anterior)",
    firstPageIsStarted:
      "La primera página en la encuesta es una página iniciada",
    showCompletedPage: "Mostrar la página completa al final (HTML finalizado)",
    goNextPageAutomatic:
      "Al responder todas las preguntas, vaya a la página siguiente automáticamente",
    showProgressBar: "Mostrar barra de progreso",
    questionTitleLocation: "Ubicación del título de la pregunta",
    requiredText: "La pregunta requerida (s) símbolo (s)",
    questionStartIndex: "Índice de inicio de la pregunta (1, 2 o 'A', 'A')",
    showQuestionNumbers: "Mostrar números de pregunta",
    questionTitleTemplate:
      "Plantilla de título de la pregunta, el valor predeterminado es: '{no}.{requiere} {título} '",
    questionErrorLocation: "Ubicación de error de la pregunta",
    focusFirstQuestionAutomatic:
      "Enfoca la primera pregunta al cambiar la página",
    questionsOrder: "Orden de elementos en la página",
    maxTimeToFinish: "Tiempo máximo para terminar la encuesta",
    maxTimeToFinishPage:
      "Tiempo máximo para terminar una página en la encuesta",
    showTimerPanel: "Mostrar panel de temporizador",
    showTimerPanelMode: "Mostrar modo de panel del temporizador",
    renderMode: "Modo de renderizado",
    allowAddPanel: "Permitir agregar un panel",
    allowRemovePanel: "Permitir eliminar el panel",
    panelAddText: "Añadiendo texto del panel",
    panelRemoveText: "Eliminar texto del panel",
    isSinglePage: "Mostrar todos los elementos en una página",
    html: "Html",
    expression: "Expresión",
    minValue: "Valor mínimo",
    maxValue: "Valor máximo",
    minLength: "Longitud mínima",
    allowDigits: "Permitir dígitos",
    minCount: "Cantidad mínima",
    maxCount: "Cantidad máxima",
    regex: "Expresión regular",
    totalText: "Texto total",
    totalType: "Tipo total",
    totalExpression: "Expresión total",
    totalDisplayStyle: "Estilo de visualización total",
    totalCurrency: "Moneda total",
    totalFormat: "Formato total",
    logoPosition: "Posición del logotipo",
    addLogo: "Añadir logo ...",
    changeLogo: "Cambiar logo ...",
    logoPositions: {
      none: "Eliminar logo",
      left: "Izquierda",
      right: "Derecha",
      top: "En la parte superior",
      bottom: "En la parte inferior",
    },
    tabs: {
      general: "General",
      fileOptions: "Opciones",
      html: "Editor HTML",
      columns: "Columnas",
      rows: "Filas",
      choices: "Opciones",
      items: "Artículos",
      visibleIf: "Visible si",
      enableIf: "Habilitar si",
      requiredIf: "Requerido si",
      rateValues: "Valores de la tasa",
      choicesByUrl: "Opciones de la web",
      matrixChoices: "Opciones predeterminadas",
      multipleTextItems: "Entradas de texto",
      numbering: "Numeración",
      validators: "Validadores",
      navigation: "Navegación",
      question: "Pregunta",
      pages: "Páginas",
      completedHtml: "HTML completado",
      completedHtmlOnCondition: "HTML completado en la condición",
      loadingHtml: "Cargando HTML",
      timer: "Horas / cuestionario",
      calculatedValues: "Valores calculados",
      triggers: "Disparadores",
      templateTitle: "Título de la plantilla",
      totals: "Totales",
      logic: "Lógica",
      layout: "Diseño",
      data: "Datos",
      validation: "Validación",
      cells: "Células",
      showOnCompleted: "Mostrar en completado",
      logo: "Logo en el título de la encuesta",
      slider: "Slider",
      others: "Otras",
    },
    editProperty: "Editar propiedad '{0}'",
    items: "[Artículos: {0}]",
    enterNewValue: "Por favor, ingrese el valor",
    noquestions: "No hay ninguna pregunta en la encuesta",
    createtrigger: "Por favor crea un gatillo",
    titleKeyboardAdornerTip: "Presione el botón Entrar para editar",
    keyboardAdornerTip:
      "Presione el botón Entrar para editar el elemento, presione el botón Eliminar para eliminar el elemento, presione ALT Plus Flecha hacia arriba o hacia abajo para mover el elemento",
    keyboardMoveItemsTip:
      "Presione ALT Plus flecha hacia arriba o flecha hacia abajo para mover el artículo",
    triggerOn: "En",
    triggerMakePagesVisible: "Hacer visibles las páginas:",
    triggerMakeQuestionsVisible: "Hacer elementos visibles:",
    triggerCompleteText: "Completa la encuesta si tiene éxito",
    triggerNotSet: "El gatillo no se establece",
    triggerRunIf: "Correr si",
    triggerSetToName: "Cambio de valor de:",
    triggerFromName: "Copie el valor de:",
    triggerRunExpression: "Ejecute esta expresión:",
    triggerSetValue: "a:",
    triggerGotoName: "Ir a la pregunta:",
    triggerIsVariable: "No coloque la variable en el resultado de la encuesta",
    triggerRunExpressionEmpty: "Por favor ingrese una expresión válida",
    noFile: "Ningún archivo elegido",
  },
  pv: {
    true: "cierto",
    false: "falsa",
    inherit: "heredar",
    show: "mostrar",
    hide: "esconder",
    default: "defecto",
    initial: "inicial",
    random: "aleatoria",
    collapsed: "colapsada",
    expanded: "expandida",
    none: "ninguna",
    asc: "ascendente",
    desc: "descendiendo",
    indeterminate: "indeterminada",
    decimal: "decimal",
    currency: "divisa",
    percent: "por ciento",
    firstExpanded: "Primer Expandido",
    off: "no mostrar",
    onPanel: "en Panel",
    onSurvey: "en Encuesta",
    list: "lista",
    progressTop: "Progreso superior",
    progressBottom: "Progreso inferior",
    progressTopBottom: "Progreso inferior/superior",
    horizontal: "horizontal",
    vertical: "vertical",
    top: "arriba",
    bottom: "abajo",
    topBottom: "arriba y abajo",
    both: "ambas",
    left: "izquierda",
    color: "color",
    date: "fecha",
    datetime: "fecha y hora",
    "datetime-local": "fecha y hora local",
    email: "Email",
    month: "mes",
    number: "número",
    password: "contraseña",
    range: "distancia",
    tel: "tel",
    text: "texto",
    time: "hora",
    url: "URL",
    week: "semana",
    hidden: "oculta",
    on: "mostrar",
    onPage: "en la página",
    edit: "editar",
    display: "mostrar",
    onComplete: "Al completarse",
    onHidden: "Al ocultarse",
    all: "todas",
    page: "página",
    survey: "encuesta",
    onNextPage: "Al cambiar página",
    onValueChanged: "Al cambiar valor",
    singlePage: "única página",
    standard: "estándar",
    questionPerPage: "Pregunta por página",
    noPreview: "sin vista previa",
    showAllQuestions: "Mostrar vista previa con todas las preguntas",
    showAnsweredQuestions: "Mostrar vista previa con preguntas contestadas",
    pages: "páginas",
    questions: "preguntas",
    requiredQuestions: "preguntas requeridas",
    correctQuestions: "preguntas correctas",
    buttons: "botones",
    underInput: "debajo de la respuesta",
    underTitle: "debajo del título"
  },
  op: {
    empty: "esta vacio",
    notempty: "no está vacío",
    equal: "es igual a",
    notequal: "no es igual a",
    contains: "contiene",
    notcontains: "no contiene",
    anyof: "cualquiera de",
    allof: "todo",
    greater: "mayor que",
    less: "menos",
    greaterorequal: "Mayor o igual",
    lessorequal: "Menos o iguales",
    and: "y",
    or: "o",
  },
  ew: {
    angular: "Usar la versión angular",
    jquery: "Usa la versión jQuery",
    knockout: "Usa la versión Knockout",
    react: "Use la versión de reacción",
    vue: "Usa la versión VUE",
    bootstrap: "Para marco de bootstrap",
    modern: "Tema moderno",
    default: "Tema predeterminado",
    orange: "Tema naranja",
    darkblue: "Tema DarkBlue",
    darkrose: "Tema de Darkrose",
    stone: "Tema de piedra",
    winter: "Tema de invierno",
    winterstone: "Tema de piedra de invierno",
    showOnPage: "Mostrar encuesta en una página",
    showInWindow: "Mostrar encuesta en una ventana",
    loadFromServer: "Encuesta de carga JSON del servidor",
    titleScript: "Scripts y estilos",
    titleHtml: "Html",
    titleJavaScript: "Javascript",
  },
  ts: {
    selectPage: "Seleccione la página para probarlo:",
    showInvisibleElements: "Mostrar elementos invisibles",
  },
  validators: {
    answercountvalidator: "Cantidad de respuestas",
    emailvalidator: "Email",
    expressionvalidator: "expresión",
    numericvalidator: "numérica",
    regexvalidator: "regex",
    textvalidator: "texto",
  },
  triggers: {
    completetrigger: "encuesta completa",
    setvaluetrigger: "valor ajustado",
    copyvaluetrigger: "Valor de copia",
    skiptrigger: "Saltar a la pregunta",
    runexpressiontrigger: "expresión de ejecución",
    visibletrigger: "Cambiar visibilidad",
  },
  p: {
    name: "nombre",
    title: {
      name: "título",
      title: "Dejarlo vacío, si es lo mismo que 'Name'",
    },
    navigationButtonsVisibility: "Navegación botones visibilidad",
    questionsOrder: "Orden",
    maxTimeToFinish: "Tiempo de finalización",
    visible: "visible",
    visibleIf: "visible si",
    questionTitleLocation: "Localización del título",
    description: "Descripción",
    state: "Expresar",
    isRequired: "se requiere",
    requiredIf: "Requerido si",
    indent: "Indentar",
    requiredErrorText: "Requerido",
    startWithNewLine: "Comenzar con nueva línea",
    innerIndent: "Sangría interior",
    page: "página",
    width: "Ancho",
    commentText: "comentario",
    valueName: "Nombre del valor",
    enableIf: "Habilitar si",
    defaultValue: "valor por defecto",
    correctAnswer: "respuesta correcta",
    readOnly: "solo lectura",
    validators: "Validadores",
    titleLocation: "Localización del título",
    hasComment: "tiene comentario",
    hasOther: "otro",
    choices: "opciones",
    choicesOrder: "orden de opciones",
    choicesByUrl: "opciones por URL",
    otherText: "otro texto",
    otherErrorText: "otro texto de Error",
    storeOthersAsComment: "Guardar otras como comentario",
    label: "etiqueta",
    showTitle: "mostrar título",
    valueTrue: "Verdadero",
    valueFalse: "Falso",
    cols: "columnas",
    rows: "filas",
    placeholder: "marcador de posición",
    optionsCaption: "Texto opciones",
    expression: "expresión",
    format: "formato",
    displayStyle: "Estilo visualización",
    currency: "divisa",
    useGrouping: "Grupo de uso",
    showPreview: "Mostrar vista previa",
    allowMultiple: "Permitir múltiples",
    imageHeight: "Altura de imagen",
    imageWidth: "ancho de la imagen",
    storeDataAsText: "Guardar datos como texto",
    maxSize: "tamaño máximo",
    html: "html",
    columns: "columnas",
    cells: "células",
    isAllRowRequired: "Todas las filas son obligatorias",
    horizontalScroll: "Scroll Horizontal",
    cellType: "Tipo de célula",
    columnsLayout: "Estilo columna",
    columnColCount: "Contador columna",
    columnMinWidth: "Ancho columna",
    rowCount: "número de filas",
    minRowCount: "Cantidad mín de filas",
    maxRowCount: "Cantidad max de filas",
    keyName: "Nombre clave",
    keyDuplicationError: "KeyDuplicationError",
    confirmDelete: "confirmar borrado",
    confirmDeleteText: "texto de confirmación de borrado",
    addRowLocation: "Localización añadir fila",
    addRowText: "Añadir texto fila",
    removeRowText: "Eliminar texto fila",
    items: "artículos",
    itemSize: "artículos",
    colCount: "Contador columna",
    templateTitle: "Título template",
    templateDescription: "Descripción template",
    allowAddPanel: "Permitir añadir panel",
    allowRemovePanel: "Permitir eliminar panel",
    panelCount: "Contador panel",
    minPanelCount: "Mín Contador Panel",
    maxPanelCount: "Max Contador Panel",
    panelsState: "Estado panel",
    panelAddText: "Panel añadir texto",
    panelRemoveText: "Panel eliminar texto",
    panelPrevText: "panel previo",
    panelNextText: "panel siguiente",
    showQuestionNumbers: "Mostrar número preguntas",
    showRangeInProgress: "Mostrar rango en progresso",
    renderMode: "Modo renderización",
    templateTitleLocation: "Localización título en template",
    rateValues: "Califica valores",
    rateMin: "Calificación mínima",
    rateMax: "Calificación máxima",
    rateStep: "Paso",
    minRateDescription: "MinRate Descripción",
    maxRateDescription: "MaxRate Descripción",
    inputType: "tipo de entrada",
    size: "Tamaño",
    locale: "lugar",
    focusFirstQuestionAutomatic:
      "Colocarse automáticamente en la primera pregunta",
    completedHtml: "HTML completado",
    completedBeforeHtml: "HTML completado antes",
    loadingHtml: "Cargando HTML",
    triggers: "disparadores",
    cookieName: "Nombre Cookie",
    sendResultOnPageNext: "Enviar resultados en la siguiente página",
    showNavigationButtons: "Mostrar botones de navegación",
    showPrevButton: "Mostrar botón previo",
    showPageTitles: "Mostrar título páginas",
    showCompletedPage: "Mostrar página de Completado",
    showPageNumbers: "Mostrar Números de Página",
    questionErrorLocation: "Localización del error de la pregunta",
    showProgressBar: "Mostrar programa",
    mode: "modo",
    goNextPageAutomatic: "Ir a la siguiente página automáticamente",
    checkErrorsMode: "Modo validar errores",
    clearInvisibleValues: "Borrar valores invisibles",
    startSurveyText: "Texto de inicio de la encuesta",
    pagePrevText: "Texto página previa",
    pageNextText: "Texto página siguiente",
    completeText: "Texto completado",
    requiredText: "Texto requerido",
    questionStartIndex: "Índice inicial de la pregunta",
    questionTitleTemplate: "Título del template de la pregunta",
    firstPageIsStarted: "Primera página iniciada",
    isSinglePage: "Página única",
    maxTimeToFinishPage: "Tiempo máximo para finalizar",
    showTimerPanel: "Mostrar temporizador",
    showTimerPanelMode: "Mostrar temporizador Modo",
    defaultPanelValue: "Valor por defecto Panel",
    defaultRowValue: "Valor por defecto Fila",
    hasNone: "Tiene opción 'Ninguno'",
    noneText: "Texto ninguno",
    text: "texto",
  },
};

editorLocalization.locales["es"] = spanishTranslation;
